import React, { useContext, useState, useEffect } from "react";
import { GameDataContext } from "../Context/GameDataContext";
import EarningsGrid from "./EarningsGrid";
import "./EarningsSection.css";

const EarningsSection = () => {
  const { takenFromAngels, wagePerSecond2024 } = useContext(GameDataContext);

  const [newTakenFromAngels, setNewTakenFromAngels] = useState(takenFromAngels);
  const [earningsData, setEarningsData] = useState([]);

  const hits = 199;
  const atBats = 795;
  const hr = 22;
  const rbi = 114;
  const r = 100;
  const bwar = 3.5;
  const fwar = 3.9;

  useEffect(() => {
    const interval = setInterval(() => {
      setNewTakenFromAngels((prev) => prev + wagePerSecond2024);
    }, 1000);

    return () => clearInterval(interval);
  }, [wagePerSecond2024]);

  useEffect(() => {
    setEarningsData([
      {
        amount: newTakenFromAngels / hits,
        unit: "H",
        statValue: hits,
        bottomUnit: "hits",
      },
      {
        amount: newTakenFromAngels / atBats,
        unit: "AB",
        statValue: atBats,
        bottomUnit: "at bats",
      },
      {
        amount: newTakenFromAngels / hr,
        unit: "HR",
        statValue: hr,
        bottomUnit: "HR",
      },
      {
        amount: newTakenFromAngels / rbi,
        unit: "RBI",
        statValue: rbi,
        bottomUnit: "RBI",
      },
      {
        amount: newTakenFromAngels / r,
        unit: "R",
        statValue: r,
        bottomUnit: "runs",
      },
      {
        amount: newTakenFromAngels / bwar,
        unit: "bWAR",
        statValue: bwar,
        bottomUnit: "bWAR",
      },
      {
        amount: newTakenFromAngels / fwar,
        unit: "fWAR",
        statValue: fwar,
        bottomUnit: "fWAR",
      },
    ]);
  }, [newTakenFromAngels]);

  return (
    <div className="earnings-section">
      <div className="earnings-container">
        <h2 className="earnings-title">
          As an Angel, Anthony Rendon has "Earned"
        </h2>
        <EarningsGrid earningsData={earningsData} />
      </div>
    </div>
  );
};

export default EarningsSection;
